import React from 'react'

import {
  ArrayField,
  BooleanField,
  Button,
  CardActions,
  Datagrid,
  DateField,
  EmailField,
  ListButton,
  NumberField,
  ReferenceField,
  SelectField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  translate
} from 'react-admin'
import AccountField from './AccountField'
import PhoneField from '../../custom/common/PhoneField'
import UserPointButton from './UserPointButton'
import UserPhoneChangeButton from './UserPhoneChangeButton'
import UserWalkHistoryButton from './UserWalkHistoryButton'
import CustomDeleteButton from '../../custom/common/CustomDeleteButton'
import AssignmentIcon from '@material-ui/icons/Assignment'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import choices from '../orders20/choices'

const ShowActions = ({history, ...props}) => {
  return (
    <CardActions>
      <ListButton basePath={props.basePath} />
      {props.data && <UserPhoneChangeButton basePath={props.basePath} record={props.data} />}
      {props.data && <UserWalkHistoryButton basePath={props.basePath} record={props.data} />}
      {props.data && <UserPointButton basePath={props.basePath} record={props.data} />}
      {props.data && <CustomDeleteButton {...props} record={props.data} submitOnEnter={false} variant="dense" />}
      {props.data && (
        <Button
          onClick={(e) => {
            history.push(
              `/talks?filter=${encodeURIComponent(
                JSON.stringify({
                  user: props.data.name,
                  order: 'ASC',
                  page: 1,
                  perPage: 25,
                  sort: 'createdAt'
                })
              )}`
            )
            e.stopPropagation()
          }}
          label="게시글 목록 이동"
        >
          <AssignmentIcon />
        </Button>
      )}
      {props.data && (
        <Button
          onClick={(e) => {
            history.push(
              `/points?filter=${encodeURIComponent(
                JSON.stringify({
                  userId: props.data.id,
                  order: 'ASC',
                  page: 1,
                  perPage: 25,
                  sort: 'createdAt'
                })
              )}`
            )
            e.stopPropagation()
          }}
          label="포인트 관리 이동"
        >
          <AttachMoneyIcon />
        </Button>
      )}
    </CardActions>
  )
}

class UserShow extends React.Component {
  render() {
    const {translate} = this.props
    return (
      <Show {...this.props} actions={<ShowActions {...this.props} />}>
        <TabbedShowLayout>
          <Tab label={translate('resources.users.summary')}>
            <TextField source="id" />
            <TextField source="name" />
            <AccountField />
            <PhoneField source="phone" />
            <EmailField label={translate('resources.users.fields.email')} source="accounts.email" />
            <NumberField source="point" />
            <NumberField source="expirePoint" />
            <BooleanField source="agreeMarketing" />
            <ReferenceField source="referrerId" reference="users" allowEmpty linkType={'show'}>
              <TextField source="name" />
            </ReferenceField>
            <DateField source="createdAt" locales="ko" showTime />
          </Tab>

          <Tab label={'산책 내역'}>
            <ArrayField source="walks" addLabel={false}>
              <Datagrid>
                <TextField source="id" sortable={false} />
                <NumberField label={'거리(m)'} source="distance" sortable={false} />
                <NumberField label={'시간(분)'} source="duration" sortable={false} />
                <DateField label={'산책일'} source="walkedAt" locales="ko" showTime sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>

          <Tab label={'반려견 정보'}>
            <ArrayField source="dogs" addLabel={false}>
              <Datagrid>
                <TextField source="id" sortable={false} />
                <TextField source="name" sortable={false} />
                <TextField source="breedName" label={'견종'} sortable={false} />
                <TextField source="gender" label={'성'} sortable={false} />
                <DateField source="birth" label={'생일'} locales="ko" showTime sortable={false} />
                <DateField source="createdAt" locales="ko" showTime sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>

          <Tab label={'주문 내역'}>
            <ArrayField source="orders" addLabel={false}>
              <Datagrid>
                <TextField source="id" sortable={false} />
                <TextField source="productName" label="상품명" sortable={false} />
                <TextField source="category" label="카테고리" sortable={false} />

                <TextField source="buyerName" sortable={false} />
                <PhoneField source="buyerPhone" sortable={false} />

                <NumberField source="amount" label="주문금액" sortable={false} />
                <NumberField source="point" sortable={false} />
                <NumberField source="deliveryFee" sortable={false} />
                <NumberField source="paidAmount" label="결제금액" sortable={false} />

                <SelectField source="method" choices={choices.methods} sortable={false} />
                <SelectField source="status" choices={choices.status} sortable={false} />
                <TextField source="trackingNumberStr" sortable={false} />
                <BooleanField source="isTodayOrder" sortable={false} />

                <DateField source="createdAt" locales="ko" showTime sortable={true} />

                <DateField source="orderDate" label="발주일" locales="ko" showTime sortable={true} />
                <DateField source="sentDate" label="배송출발일" locales="ko" showTime sortable={true} />
                <DateField source="deliveryDate" label="배송완료일" locales="ko" showTime sortable={true} />

                <TextField source="sellerName" label="업체명" sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>

          <Tab label={'댓글 내역'}>
            <ArrayField source="comments" addLabel={false}>
              <Datagrid>
                <TextField source="id" label={'댓글 ID'} sortable={false} />
                <TextField source="content" label={'댓글 내용'} sortable={false} />
                <DateField source="createdAt" label={'댓글 작성 날짜'} locales="ko" showTime sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>

          <Tab label={'좋아요 내역'}>
            <ArrayField source="likes" addLabel={false}>
              <Datagrid>
                <TextField source="id" label={'포스팅 ID'} sortable={false} />
                <DateField source="createdAt" label={'좋아요 누른 날짜'} locales="ko" showTime sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>

          <Tab label={'펫친 내역'}>
            <ArrayField source="friends" addLabel={false}>
              <Datagrid>
                <TextField source="friendId" label={'친구 ID'} sortable={false} />
                <BooleanField source="accepted" label={'승인 여부'} sortable={false} />
                <DateField source="createdAt" label={'펫친 신청 날짜'} locales="ko" showTime sortable={false} />
              </Datagrid>
            </ArrayField>
          </Tab>
        </TabbedShowLayout>
      </Show>
    )
  }
}

export default translate(UserShow)
