import React, {useState} from 'react'
import {
  TextField,
  BooleanInput,
  DateInput,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  ImageField,
  LongTextInput,
  maxLength,
  SelectInput,
  SimpleForm,
  ArrayInput,
  SimpleFormIterator,
  TextInput,
  ImageInput,
  NumberInput
} from 'react-admin'
import EditActions from '../../custom/common/EditActions'
import {required} from '../../validators'
import EditToolbar from '../../custom/common/EditToolbar'
import OrderImageInput from '../../custom/common/DragOrderImage/OrderImageInput'

const fullPageHrStyle = {
  width: '100%',
  borderTop: '1px solid #ccc', // 변경 가능한 가로선 스타일
  margin: '16px 0'
}

export default (props) => {
  const [imageData, setImageData] = useState([])
  const [bottomImageData, setBottomImageData] = useState([])
  const [urlImageData, setUrlImageData] = useState([])

  return (
    <Edit {...props} actions={<EditActions />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <SelectInput source="type" choices={[{id: 'event', name: '이벤트'}]} validate={required} />
        <LongTextInput source="title" validate={[required, maxLength(150)]} />
        <LongTextInput source="content" validate={[required]} />
        <NumberInput source="rolling_order" validate={[required]} />
        <TextField label="노출 순서를 원하시면 해당 기간내에 다른 이벤트랑 순서가 겹치지않는지 확인하시고 입력해주세요." />
        <LongTextInput source="pushTitle" defaultValue={'-'} validate={[required, maxLength(150)]} />

        <ImageInput source="titleImagePath" accept="image/*" validate={required}>
          <ImageField source="image" title="title" />
        </ImageInput>

        <FormDataConsumer>
          {({formData, ...rest}) => {
            if (imageData.length > 0) formData.images = imageData
            if (bottomImageData.length > 0) formData.bottomImages = bottomImageData
            if (urlImageData.length > 0) formData.urlImageData = urlImageData

            return (
              <>
                <OrderImageInput
                  label="상세 이미지"
                  placeholder={<p>이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.</p>}
                  multiple
                  setState={setImageData}
                  source="images"
                  accept="image/*"
                >
                  <ImageField source="image" title="title" />
                </OrderImageInput>

                <OrderImageInput
                  label="하단 이미지"
                  placeholder={<p>이미지 여러 개 끌어다 넣거나, 클릭 후 여러 개 선택하십시오.</p>}
                  multiple
                  setState={setBottomImageData}
                  source="bottomImages"
                  accept="image/*"
                >
                  <ImageField source="image" title="title" />
                </OrderImageInput>

                <br />
                <hr style={fullPageHrStyle} />
                <br />

                {formData && formData.type === 'event' ? (
                  <SelectInput
                    source="form"
                    label="이벤트 형태"
                    choices={[
                      {id: 'general', name: '단순 게시형'},
                      {id: 'entry', name: '단순 응모형'},
                      {id: 'pick', name: '단일 선택 응모형'},
                      {id: 'attendance', name: '출석 이벤트형'}
                    ]}
                    validate={[required]}
                  />
                ) : (
                  <div></div>
                )}

                {formData && formData.type === 'event' && formData.form === 'pick' ? (
                  <ArrayInput source="viewOptions" label="선택지 보기 옵션" validate={required}>
                    <SimpleFormIterator>
                      <TextInput source="title" label="선택지 보기 아이템" validate={[required]} />
                      <SelectInput
                        source="type"
                        label="버튼 형태"
                        choices={[
                          {id: 'selectionButton', name: '선택 버튼'},
                          {id: 'entryButton', name: '응모 버튼'}
                        ]}
                        validate={[required]}
                      />
                      <ImageInput source="beforeSelection" accept="image/*" validate={required}>
                        <ImageField source="image" title="title" />
                      </ImageInput>
                      <ImageInput source="afterSelection" accept="image/*" validate={required}>
                        <ImageField source="image" title="title" />
                      </ImageInput>
                      <ImageInput source="expiredSelection" accept="image/*" validate={required}>
                        <ImageField source="image" title="title" />
                      </ImageInput>
                    </SimpleFormIterator>
                  </ArrayInput>
                ) : (
                  <div></div>
                )}

                {formData && formData.type === 'event' && formData.form === 'attendance' ? (
                  <ArrayInput source="viewOptions" label="출석 이벤트 내용 추가" validate={required}>
                    <SimpleFormIterator>
                      <DateInput source="viewAttendanceDate" label="출석 이벤트 해당 날짜" validate={[required]} />
                    </SimpleFormIterator>
                  </ArrayInput>
                ) : (
                  <div></div>
                )}

                <BooleanInput
                  source="isCtaButtonAvailable"
                  validate={[required]}
                  label="CTA 버튼을 활성화 하시겠습니까?"
                  defaultValue={false}
                ></BooleanInput>

                {formData && formData.type === 'event' && formData.isCtaButtonAvailable === true ? (
                  <TextInput source="url" label="링크 URL" />
                ) : (
                  <div></div>
                )}
                {formData && formData.type === 'event' && formData.isCtaButtonAvailable === true ? (
                  <OrderImageInput
                    label="URL 이미지"
                    placeholder={<p>이미지를 넣어주세요</p>}
                    multiple
                    setState={setUrlImageData}
                    source="urlImage"
                    accept="image/*"
                  >
                    <ImageField source="image" title="title" />
                  </OrderImageInput>
                ) : (
                  <div></div>
                )}
              </>
            )
          }}
        </FormDataConsumer>
        <br />

        <br />
        <hr style={fullPageHrStyle} />
        <br />

        <BooleanInput source="push" validate={[required]} defaultValue={false} />
        <TextField label="예약할 푸시 시간은 현재(푸시 업로드 시점)보다 2시간 이후이어야 등록되며, 2시간 이내이면 즉시 푸시됩니다. 또한, 각 푸시 발송 시간은 2시간 이상 차이가 나야 정상 등록됩니다." />
        <DateTimeInput
          label="푸시 예약 발송 일시"
          source="pushDateTime"
          defaultValue={new Date('2021-05-01 12:00:00')}
        />

        <DateInput label="시작 날짜" source="startDate" />
        <DateInput label="종료 날짜" source="endDate" />

        <DateTimeInput source="periodStart" locales="ko" validate={[required]} defaultValue={new Date()} />
        <DateTimeInput source="periodEnd" locales="ko" validate={[required]} defaultValue={new Date()} />
      </SimpleForm>
    </Edit>
  )
}
