import React from 'react'
import compose from 'recompose/compose'
import {withStyles} from '@material-ui/core/styles'
import {crudDelete, crudGetOne, translate} from 'ra-core'
import {Button as AdminButton, UPDATE} from 'react-admin'
import Button from '@material-ui/core/Button'
import ContentInfo from '@material-ui/icons/Add'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import {dataProvider} from '../../App'
import {connect} from 'react-redux'

const styles = (theme) => ({
  dialog: {
    width: '80%'
  },
  button: {}
})

class UserPointButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      description: '',
      point: 0
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleDone = this.handleDone.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  async updateOrder() {
    const {dispatchCrudGetOne, basePath, record} = this.props
    const {description, point} = this.state
    await dataProvider(UPDATE, 'users', {
      id: record.id,
      data: {description, point}
    })
    dispatchCrudGetOne('users', record.id, basePath)
  }

  handleClick() {
    this.setState({isOpen: true, description: '', point: 0})
  }

  handleCancel() {
    this.setState({isOpen: false})
  }

  async handleDone(e) {
    e.stopPropagation()
    await this.updateOrder()
    this.setState({isOpen: false})
  }

  handleChange(e) {
    const {name, value} = e.target
    this.setState({[name]: value})
  }

  render() {
    const {isOpen, description, point} = this.state
    const {classes} = this.props

    return (
      <div>
        <AdminButton label="포인트" onClick={this.handleClick}>
          <ContentInfo />
        </AdminButton>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          onEntering={this.handleEntering}
          aria-labelledby="confirmation-dialog-title"
          open={isOpen}
          classes={{paper: classes.dialog}}
        >
          <DialogTitle id="confirmation-dialog-title">포인트 추가</DialogTitle>
          <DialogContent>
            <TextField
              label={'문구'}
              style={{width: '100%'}}
              margin="normal"
              name="description"
              onChange={this.handleChange}
              value={description}
            />
            <TextField
              label={'포인트'}
              style={{width: '100%'}}
              margin="normal"
              name="point"
              onChange={this.handleChange}
              value={point}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              취소
            </Button>
            <Button disabled={!description || point === 0} onClick={this.handleDone} color="primary">
              확인
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default compose(
  connect(null, {
    dispatchCrudGetOne: crudGetOne,
    dispatchCrudDelete: crudDelete
  }),
  translate,
  withStyles(styles)
)(UserPointButton)
