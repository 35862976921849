import React from 'react'
import compose from 'recompose/compose'
import {withStyles} from '@material-ui/core/styles'
import {crudDelete, crudGetOne, translate} from 'ra-core'
import {Button as AdminButton, DateField, DateInput, UPDATE} from 'react-admin'
import Button from '@material-ui/core/Button'
import ContentInfo from '@material-ui/icons/Add'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Dialog from '@material-ui/core/Dialog'
import TextField from '@material-ui/core/TextField'
import {dataProvider} from '../../App'
import {connect} from 'react-redux'

const styles = (theme) => ({
  dialog: {
    width: '80%'
  },
  button: {}
})

class UserWalkHistoryButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
      distance: 0,
      duration: 0,
      walkedAt: '',
      walkPoint: 0
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.handleDone = this.handleDone.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  async updateOrder() {
    const {dispatchCrudGetOne, basePath, record} = this.props
    const {distance, duration, walkedAt, walkPoint} = this.state
    await dataProvider('WALK_HISTORY', 'users', {
      id: record.id,
      data: {distance, duration, walkedAt, walkPoint}
    })
    dispatchCrudGetOne('users', record.id, basePath)
  }

  handleClick() {
    this.setState({isOpen: true, distance: 0, duration: 0, walkedAt: new Date(), walkPoint: 0})
  }

  handleCancel() {
    this.setState({isOpen: false})
  }

  async handleDone(e) {
    e.stopPropagation()
    await this.updateOrder()
    this.setState({isOpen: false})
  }

  handleChange(e) {
    const {name, value} = e.target
    this.setState({[name]: value})
  }

  render() {
    const {isOpen, distance, duration, walkedAt, walkPoint} = this.state
    const {classes} = this.props

    return (
      <div>
        <AdminButton label="산책기록 변경" onClick={this.handleClick}>
          <ContentInfo />
        </AdminButton>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          maxWidth="xs"
          onEntering={this.handleEntering}
          aria-labelledby="confirmation-dialog-title"
          open={isOpen}
          classes={{paper: classes.dialog}}
        >
          <DialogTitle id="confirmation-dialog-title">산책기록 변경</DialogTitle>
          <DialogContent>
            <TextField
              label={'거리를 입력해주세요 (단위 : m)'}
              style={{width: '100%'}}
              margin="normal"
              name="distance"
              onChange={this.handleChange}
              value={distance}
            />
            <TextField
              label={'시간을 입력해주세요 (단위 : 초)'}
              style={{width: '100%'}}
              margin="normal"
              name="duration"
              onChange={this.handleChange}
              value={duration}
            />
            <TextField
              label="산책 종료한 시점을 입력해주세요"
              type="datetime-local" // This ensures a date picker appears
              InputLabelProps={{shrink: true}} // Ensures label stays above the input
              style={{width: '100%'}}
              margin="normal"
              name="walkedAt"
              onChange={this.handleChange}
              value={walkedAt}
            />
            <TextField
              label={'추가하실 포인트를 입력해주세요'}
              style={{width: '100%'}}
              margin="normal"
              name="walkPoint"
              onChange={this.handleChange}
              value={walkPoint}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCancel} color="primary">
              취소
            </Button>
            <Button disabled={!distance || !duration || !walkedAt} onClick={this.handleDone} color="primary">
              확인
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

export default compose(
  connect(null, {
    dispatchCrudGetOne: crudGetOne,
    dispatchCrudDelete: crudDelete
  }),
  translate,
  withStyles(styles)
)(UserWalkHistoryButton)
